body,
html {
    margin: 0;
    width: 100%;
    height: 100%;
    min-height: 100% !important;

}
#App,#root{
    height: 100%;
    min-height: 100% !important;

}

*{
    margin: 0;
    padding: 0;
}
body{
    overflow:hidden;
}