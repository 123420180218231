.myFrame {
  width: 100% !important;
  height: 100%  !important;
  min-height: 100vh;
  overflow: hidden !important;
  display: block;       /* iframes are inline by default */
  border: none;
  top: 0; right: 0;
  bottom: 0; left: 0;
}

.container-iframe {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  height: 120%;
}

.logo {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;

}
.logo img{
    max-width: 260px;
}
.logo small{
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.carregando{
    margin: 50px;
}
